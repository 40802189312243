<template>
    <form
        class="search-bar"
        @submit.prevent="updateSearchQuery"
    >
        <BaseInput
            v-model="searchQuery"
            class="search-bar__input"
            :placeholder="placeholderText"
        >
            <template #after>
                <BaseButton
                    class="search-bar__button"
                    icon="search"
                    size="large"
                >
                    <span class="search-bar__button__text">Zoeken</span>
                </BaseButton>
            </template>
        </BaseInput>
    </form>
</template>

<script setup>
import { useDebounceFn } from '@vueuse/core';

defineProps({
    placeholderText: {
        type: String,
        default: 'Zoek naar thema’s, dossiers, producten en diensten'
    }
});

// Define the router and route.
const route = useRoute();
const router = useRouter();

const onSearchPage = computed(() => {
    return route.name === 'zoeken' || route.name === 'zoeken-contenttype';
});

// Local search state of the search bar.
const searchQuery = ref(onSearchPage.value ? route.query.q : '');

const updateSearchQuery = useDebounceFn(() => {
    router.push({
        path: onSearchPage.value ? route.path : '/zoeken',
        query: {
            q: searchQuery.value || undefined
        }
    });
}, 500);

// Update the route.query.q on input if we are on the search page.
if (onSearchPage.value) {
    watch(searchQuery, () => updateSearchQuery());
}

onMounted(() => {
    const searchInput = document.querySelector('.search-bar__input');

    if (onSearchPage.value && searchInput) {
        searchInput?.focus();
    }
});
</script>

<style lang="less" src="./SearchBar.less" />
